<template>
	<div class="null-page">
		<van-loading type="spinner" size="36px" color="#1989fa" vertical>加载中...</van-loading>
	</div>
</template>

<script>

import {
  getAllDic
  ,
  getElevatorDetail
} from '@/api/public'

export default {
  components: {},
  data () {
    return {
      text: '找不到电梯'
    }
  },
  created () {
    this.getDatail()
    // this.getAllDic()
  },
  methods: {
    getDatail () {
      try {
        const e = this.getUrlParam().e
        console.log(e)
        if (!e) {
          this.$router.replace({
            path: 'Null'
          })
          return
        }

        const query = {
          elevatorId: e
        }

        getElevatorDetail(query).then(response => {
          const elevator = response.data.elevatorResult
          const list = response.data.maintenanceOrderList

          sessionStorage.setItem('list', JSON.stringify(list))

          if (elevator) {
            this.$router.replace({
              path: 'ScanElevator',
              query: {
                // list: JSON.stringify(list),

                elevatorId: e
                // elevator: elevator
              }
            })
          } else {
            this.$router.replace({
              path: 'Null'
            })
          }
        })
      } catch (error) {
        console.info(error)
      }
    },
    getUrlParam () {
      const url = location.search
      this.winUrl = url
      const theRequest = new Object()
      if (url.indexOf('?') != -1) {
        const str = url.substr(1)
        const strs = str.split('&')
        for (let i = 0; i < strs.length; i++) {
          if (strs[i].indexOf('=') != -1) {
            theRequest[strs[i].split('=')[0]] = (strs[i].split('=')[1])
          } else {
            theRequest[strs[i].substring(0, 1)] = (strs[i].substring(1, strs[i].length))
          }
        }
      }
      return theRequest
    },

    getAllDic () {
      getAllDic().then(res => {
        sessionStorage.setItem('dicList', JSON.stringify(res.data))
      })
    }
  }
}
</script>

<style scoped>
	.null-page {
		background-color: #F2F2F2;
		width: 100%;
		min-height: 100vh;
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;
		flex: 1;
	}

	.img {
		width: 200px;
		height: 150px;
	}

	p {
		margin-top: 10px;
		font-size: 16px;
		color: #999;
	}
</style>
